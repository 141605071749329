<template>
  <!-- ==================== Start Slider ==================== -->

  <header class="mobile-app valign">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 valign">
          <div class="caption text-center">
            <img src="../../public/img/logo.png" alt="">
            <div class="butons mt-40">
              <a href="https://apps.apple.com/br/app/palpitero-fc/id6738431960" class="butn-bord-dark rounded buton">
                <span>Apple Store</span>
                <i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                  </svg></i>
              </a>
              &nbsp;&nbsp;
              <a href="https://play.google.com/store/apps/details?id=com.palpitero" class="butn-bord-dark rounded buton">
                <span>Google Play</span>
                <i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
                  </svg></i>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-5 offset-lg-1 text-center">
          <div class="img">
            <img style="width: 350px;" src="../../public/img/phone.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="brands pt-80 pb-80 bg-dark" data-overlay-dark="0">
      <div class="container">
        <div class="head mb-60">
          <div class="row">
            <div class="col-12 col-md-3 text-center">
              <img src="../../public/img/phone.png" style="width: 170px;">
            </div>
            <div class="col-12 col-md-9">
              <br>
              <br>
              <h2 style="color: #FFA500">Palpites de forma simples</h2>
              <br>
              <br>
              <p>Com o Palpitero, você pode fazer seus palpites de forma simples, prática e sem tomar muito do seu tempo. O app oferece uma experiência rápida e intuitiva, permitindo que você participe das principais ligas de futebol do mundo. Acompanhe as competições mais emocionantes, compartilhe suas previsões e se divirta desafiando amigos e outros usuários enquanto testa seus conhecimentos sobre o esporte!</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-9 text-right">
              <br>
              <br>
              <br>
              <h2 style="color: #FFA500">Espionagem</h2>
              <br>
              <br>
              <p>Com o Palpitero, você pode espiar os palpites de outros jogadores e descobrir como eles estão apostando nos resultados. Use essa função para se inspirar ou desafiar suas próprias previsões e torne a experiência ainda mais interativa e estratégica!</p>
            </div>
            <div class="col-12 col-md-3 text-center">
              <img src="../../public/img/phone2.png" style="width: 170px;">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3 text-center">
              <img src="../../public/img/phone3.png" style="width: 170px;">
            </div>
            <div class="col-12 col-md-9">
              <br>
              <br>
              <h2 style="color: #FFA500">Rankings nacionais</h2>
              <br>
              <br>
              <p>No Palpitero, você pode competir em rankings semanais, mensais e anuais, mostrando suas habilidades de palpitar e desafiando outros jogadores. Suba no ranking, conquiste posições de destaque e prove que você é o melhor quando o assunto é prever resultados!</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-9 text-right">
              <br>
              <br>
              <h2 style="color: #FFA500">Grupos</h2>
              <br>
              <br>
              <p>No Palpitero, a diversão é ainda maior quando compartilhada! Crie grupos personalizados para jogar com seus amigos, familiares ou colegas de trabalho e torne as competições ainda mais emocionantes. Dentro dos grupos, vocês podem comparar palpites, disputar rankings exclusivos e compartilhar momentos de alegria e zoação a cada rodada. É a maneira perfeita de unir diversão, amizade e paixão pelo futebol, tudo em um só lugar. Mostre quem manda no grupo e seja o campeão entre os seus!</p>
            </div>
            <div class="col-12 col-md-3 text-center">
              <img src="../../public/img/phone4.png" style="width: 170px;">
            </div>
          </div>
        </div>
      </div>
    </section>

  <!-- ==================== End Slider ==================== -->
</template>