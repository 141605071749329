<template>
  <section class="brands pt-80 pb-80 bg-dark" data-overlay-dark="0">
    <div class="container">
      <div class="head mb-60">
        <div class="row">
          <div class="col-12 text-center">
            <br />
            <br />
            <div class="row">
              <div class="col">
                <div class="picture">
                  <img
                    :src="group.picture"
                    alt="Imagem do grupo"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row text-center">
              <div class="col">
                <h2 class="text-white">{{ group.name }}</h2>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col">
                <a
                  class="butn-bord-dark rounded buton"
                  :href="`palpitero://groups/invite/${group.inviteCode}`"
                >
                  <span>Entrar no grupo</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
</style>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  setup() {
    const route = useRoute();
    const groupId = computed(() => route.params.groupId);
    const group = ref({});

    onMounted(() => {
      fetch(`https://api.palpitero.com/groups/${groupId.value}`)
        .then((response) => response.json())
        .then((data) => {
          group.value = data;
          // Assim que o grupo é carregado, definimos o head
          useHead({
            title: group.value.name || 'Meu Site',
            meta: [
              { property: 'og:title', content: group.value.name || '' },
              {
                property: 'og:description',
                content: `Entre no grupo ${group.value.name || ''}!`
              },
              { property: 'og:image', content: group.value.picture || '' },
              {
                property: 'og:url',
                content: `https://palpitero.com/groups/${groupId.value}`
              },
              { property: 'og:type', content: 'website' },
              { name: 'twitter:card', content: 'summary_large_image' },
              { name: 'twitter:title', content: group.value.name || '' },
              {
                name: 'twitter:description',
                content: `Entre no grupo ${group.value.name || ''}!`
              },
              { name: 'twitter:image', content: group.value.picture || '' }
            ]
          });
        })
        .catch((error) => {
          console.error('Erro ao buscar o grupo:', error);
        });
    });

    return {
      groupId,
      group
    };
  }
};
</script>