<template>
  <section class="brands pt-80 pb-80 bg-dark" data-overlay-dark="0">
    <div class="container">
      <div class="head mb-60">
        <div class="row">
          <div class="col-12">
            <div class="text" v-html="content"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* global marked */

export default {
  data() {
    return {
      content: '',
    };
  },
  mounted() {
    fetch(
      'https://raw.githubusercontent.com/KopplerTech/docs/main/palpiterofc/privacidade.md'
    )
      .then((response) => response.text())
      .then((markdown) => {
        this.content = marked.parse(markdown);
      })
      .catch((error) => {
        console.error('Erro ao buscar o arquivo Markdown:', error);
      });
  },
};
</script>

<style scoped>
/* Adicione seus estilos aqui */
</style>
