import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';

const app = createApp(App);

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Palpitero FC';
  }
});

const head = createHead();

app.use(router);
app.use(head);
app.mount('#app');
