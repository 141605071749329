<template>
  <section class="brands pt-80 pb-80 bg-dark" data-overlay-dark="0">
    <div class="container">
      <div class="head mb-60">
        <div v-if="countUsers" class="row">
          <div class="col-12 text-center">
            <br />
            <br />
            <h1>ESTAMOS COM</h1>
            <h1 style="font-size: 200px; color: #ffa500">
              {{ countUsers }}
            </h1>
            <h1>JOGADORES</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      countUsers: undefined,
    };
  },
  mounted() {
    fetch(`https://api.palpitero.com/users/count`)
      .then((response) => response.json())
      .then((data) => {
        this.countUsers = data;
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  },
};
</script>
