import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import UseTerms from './components/UseTerms.vue';
import GroupInvite from './components/GroupInvite.vue';
import CountUsers from './components/CountUsers.vue';
import DownloadApp from './components/DownloadApp.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { title: 'Palpitero FC · O melhor aplicativo de palpites do Brasil' },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPolicy,
    meta: { title: 'Política de Privacidade · Palpitero FC' },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: UseTerms,
    meta: { title: 'Termos de Uso · Palpitero FC' },
  },
  {
    path: '/players',
    name: 'Players',
    component: CountUsers,
    meta: { title: 'Jogadores · Palpitero FC' },
  },
  {
    path: '/:groupId',
    component: GroupInvite,
    meta: { title: 'Convite para o grupo · Palpitero FC' },
  },
  {
    path: '/download',
    component: DownloadApp,
    meta: { title: 'Baixe o aplicativo · Palpitero FC' },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
